import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "components/Layout";
import dateIcon from "../img/calendar.svg";
import timeIcon from "../img/watch.svg";

const BlogPost = ({ data, pageContext }) => {
  console.log(data.post.frontmatter.title);
  return (
    <Layout>
      <section className="container">
        <div className="row">
          <article className="blog-post">
            <hr></hr>
            <GatsbyImage
              image={data.blogImage.childImageSharp.gatsbyImageData}
              alt="featured-blog-image"
              style={{ height: "25em", objectFit: "cover" }}
            />
            <div
              className="mt-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderTop: "1px solid black",
              }}
            >
              <div
                className="mt-2 d-flex align-items-center"
                style={{ fontSize: "0.85em" }}
              >
                <img src={dateIcon} alt="date-icon" />
                <span className="ms-1">{data.post.frontmatter.date}</span>
              </div>
              <div
                className="mt-2 d-flex align-items-center"
                style={{ fontSize: "0.85em", marginLeft: "1.8em" }}
              >
                <img src={timeIcon} alt="reading-time-icon" />
                <span className="ms-1">{pageContext.readingTime}</span>
              </div>
            </div>
            <h1 className="mt-2">{data.post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.post.html }}></div>
          </article>
        </div>
      </section>
    </Layout>
  );
};

export default BlogPost;

export const blogPostQuery = graphql`
  query ($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
    }
    blogImage: file(name: { eq: "blog-article-default" }) {
      childImageSharp {
        gatsbyImageData(
          # quality: 20
          aspectRatio: 1.5
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
    }
  }
`;
